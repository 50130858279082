import React, { useEffect, useState } from 'react'
import failPayment from '../Assets/images/payment_failed.png'
import Header from './header'

const Fail = () => {
    const [load, upadateLoad] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            upadateLoad(false);
        }, 1200);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <div style={{ padding: "15px" }}>
                <Header />
                <div className="coupon-sec">
                    <div className="wrapper">
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'center', justifyContent: 'center', margin: 'auto'  }}>
                            <img height={500} src={failPayment} alt="" srcset="" />
                            <h4>Whoops! Payment failed. Please check your details and try again.</h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Fail
