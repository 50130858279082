import React from 'react'
import './success.css';

const Success = ({handlePage}) => {
    return (
        <>
            <div className="container-body">
                <h1>Secure Payment</h1>
                <form>
                    <label htmlFor="card_number">Card Number:</label>
                    <input type="text" id="card_number" placeholder="1234 5678 9012 3456" />
                    
                    <label htmlFor="expiry_date">Expiry Date:</label>
                    <input type="text" id="expiry_date" placeholder="MM/YY" />
                    
                    <label htmlFor="cvv">CVV:</label>
                    <input type="text" id="cvv" placeholder="123" />
                    
                    <label htmlFor="name">Name on Card:</label>
                    <input type="text" id="name" placeholder="John Doe" />
                    
                    <input type="button" value="Pay Now" onClick={handlePage} />
                </form>
            </div >
        </>
    )
}

export default Success
