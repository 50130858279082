import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../Assets/images/logo-mini.svg";
import search from "../Assets/images/search.png";
import {
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
} from "react-icons/ai";
import { ToastContainer, toast } from 'react-toastify';

function NavBar() {

  const location = useLocation();
  const navigate = useNavigate();
  const [expand, setExpanded] = useState(false);
  const [navColour, setNavbar] = useState(false);
  const [page, setPage] = useState('');
  const [code, setCode] = useState('');
  const [loader, setLoader] = useState(false);
  const [activeLink, setActiveLink] = useState('/home');
  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
      function scrollHandler() {
        if (window.scrollY >= 20) {
          setNavbar(true);
        } else {
          setNavbar(false);
        }
      }

      window.addEventListener("scroll", scrollHandler);
      return () => {
        window.removeEventListener("scroll", scrollHandler);
      };
  }, []);

  useEffect(() => {
       setActiveLink(location.pathname);
  }, [location]);

  const handleClick = (e) => {
    if (e.key === 'Enter' && code) {
        setLoader(true);
        fetch(`${url}/getDocumentDetail`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({code}),
        }).then((response) => { return response.json();
        }).then((res) => {
            setLoader(false);
            if (res.status) {
                toast.success('Uploaded Document Found Successfully!', {
                    position: 'top-right'
                });
                navigate('/preview', { state: { data: res.data } });
            } else {
                toast.error('Not Found Uploaded Document!', {
                    position: 'top-right'
                });
            }
        }).catch((error) => {
              setLoader(false);
              console.log("----------error---------", error);
        });
    }
  }

  return (
    <>
        {!page &&
            <Navbar
              expanded={expand}
              fixed="top"
              expand="md"
              className={expand ? "navbar navbar_show" : "navbar" }
            >
              <Container>
                <Navbar.Brand href="/" className="d-flex custom-logo">
                  <img src={logo} className="img-fluid logo" alt="brand" />
                  <div className="title-header">MINISTRY OF INTERIOR</div>
                </Navbar.Brand>
                <div className="search-box-responsive">
                  <img src={search} alt="search" className="img-search"/>
                  <input type="text" className="form-control search-input" placeholder="Search Code"
                  onChange={(e) => setCode(e.target.value)}
                  onKeyPress={handleClick}/>
                </div>
                <Navbar.Toggle
                  aria-controls="responsive-navbar-nav"
                  onClick={() => {
                    setExpanded(expand ? false : "expanded");
                  }}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="ms-auto" defaultActiveKey="#home">
                    <Nav.Item>
                      <Nav.Link as={Link} to="/" className={activeLink === '/' && 'nav-active'} onClick={() => setExpanded(false)}>
                        <AiOutlineHome style={{ marginBottom: "2px" }} /> Home
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        as={Link}
                        to="/about"
                        className={activeLink === '/about' && 'nav-active'}
                        onClick={() => setExpanded(false)}
                      >
                        <AiOutlineUser style={{ marginBottom: "2px" }} /> About
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link
                        as={Link}
                        to="/project"
                        className={activeLink === '/project' && 'nav-active'}
                        onClick={() => setExpanded(false)}
                      >
                        <AiOutlineFundProjectionScreen
                          style={{ marginBottom: "2px" }}
                        />{" "}
                        Location
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="link-preview">
                      <Nav.Link
                        as={Link}
                        to="/add-document"
                        className={activeLink === '/add-document' && 'nav-active'}
                        onClick={() => {setExpanded(false); setPage('upload');}}
                      >
                        <AiOutlineFundProjectionScreen
                          style={{ marginBottom: "2px" }}
                        />{" "}
                        Upload Documents
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="link-preview">
                      <Nav.Link
                        as={Link}
                        to="/prepaid-coupon"
                        className={activeLink === '/prepaid-coupon' && 'nav-active'}
                        onClick={() => {setExpanded(false); setPage('coupon');}}
                      >
                        <AiOutlineFundProjectionScreen
                          style={{ marginBottom: "2px" }}
                        />{" "}
                        Prepaid Coupon
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="search-box">
                      <label style={{ fontSize: "14px", fontWeight: "700" }}>Provide Uploaded Doc ID</label>
                      <img src={search} alt="search" className="img-search"/>
                      <input type="text" className="form-control search-input" placeholder="Search Code"
                      onChange={(e) => setCode(e.target.value)}
                      onKeyPress={handleClick}/>
                    </Nav.Item>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
        }
        <ToastContainer />
        {loader && (
            <div className="loadWrapper">
              <div className="loader"></div>
            </div>
        )}
    </>
  );
}

export default NavBar;
