const BASE_URL = process.env.REACT_APP_API_URL;

const apiService = {
  apiCall: async (body, endPoint) => {
    try {
      const response = await fetch(`${BASE_URL + endPoint}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      return await response.json();
    } catch (error) {
      console.log('=======ERROR=======', error)
    }
  },
  addDoc: async (formData, endPoint) => {
    try {
      const response = await fetch(`${BASE_URL + endPoint}`, {
        method: "POST",
        body: formData,
      });
      return await response.json();
    } catch (error) {
      console.log('=======ERROR=======', error)
    }
  }
};

export default apiService;
