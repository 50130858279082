import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import "./fonts/stylesheet.css";
import "./style.css";
import "./Responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/home";
import AddDocument from "./components/pages/add-doc";
import Congratulation from "./components/pages/congratulation";
import Success from "./components/pages/shared/Success";
import Coupon from "./components/pages/coupon-one";
import PreviewDocument from "./components/pages/preview-document";
import 'react-toastify/dist/ReactToastify.css';
import Fail from "./components/Fail";

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/add-document" element={<AddDocument />} />
        <Route path="/prepaid-coupon" element={<Coupon />} />
        <Route path="/congratulation" element={<Congratulation />} />
        <Route path="/success" element={<Success />} />
        <Route path="/fail-payment" element={<Fail />} />
        <Route path="/preview" element={<PreviewDocument />} />
      </Routes>
    </Router>
  );
}

export default App;
