import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import btnArrow from "../../Assets/images/btn-arrow.png";
import blackArrow from "../../Assets/images/black-arrow.png";
import countriesData from "../../Assets/country_code.json";
import Select from "react-select";
import Checkout from "./shared/Checkout";
import Success from "./shared/Success";
import Header from "../header.js";
import apiService from "../services/api-service.js";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import search from "../../Assets/images/search.png";

function Coupon() {
  const navigate = useNavigate();
  const [page, setPage] = useState("amount");
  const amounts = [5, 10, 15, 50, 100];
  const [amount, setAmount] = useState(amounts[0]);
  const [mobile, setMobile] = useState("");
  const [countryCode, setCountryCode] = useState("+965");
  const [loader, setLoader] = useState(false);
  const [isGenerate, setIsGenerate] = useState(false);
  const [isTopUp, setIsTopUp] = useState(false);
  const [topUpDetail, setTopUpDetail] = useState(null);
  const [paymentDetail, setPaymentDetail] = useState(null);
  const [code, setCode] = useState("");
  const [historyCode, setHistoryCode] = useState("");
  const [coupon_code, setCouponCode] = useState(generateCouponCode(4));
  const date = new Date();
  const [error, setError] = useState({
    mobile: null,
    countryCode: null,
  });
  const defaultOptionIndex = countriesData.findIndex(
    (country) => country.dialCode === countryCode
  );

  useEffect(() => {
    if (!isGenerate) {
      checkCode();
    }
  }, []);

  const checkCode = async () => {
    try {
      const response = await apiService.apiCall(
        { code: coupon_code, type: "coupon" },
        "/checkCode"
      );
      if (response.status) {
        const code = generateCouponCode(4);
        setCouponCode(code);
        checkCode();
      } else {
        setIsGenerate(true);
      }
    } catch (error) {
//      toast.error("Something Went Wrong!", {
//        position: "top-right",
//        autoClose: 1000,
//      });
    }
  };

  const handlePage = async () => {
    console.log(page);
    if (page === "amount" || page === "topUp") {
      let errorShow = {};
      if (!mobile) {
        errorShow.mobile = "*Please Enter Phone Number";
      }
      if (!countryCode) {
        errorShow.countryCode = "*Please Select Country Code";
      }
      if (page === 'amount') setIsTopUp(false);
      setError({ ...error, ...errorShow });
      if (Object.values(errorShow).length) {
        return;
      } else {
        setLoader(true);
        setPage("success");
        setTimeout(() => {
          setLoader(false);
        }, 500);
      }
    } else if (page === "success") {
      setPage("payment");
    } else if (page === "payment") {
      setLoader(true);
      addCoupon();
    }
  };

  const addCoupon = async (detail) => {
    const body = {
      amount,
      remaining_amount: amount,
      initial_amount: amount,
      country_code: countryCode,
      phone_no: mobile,
      coupon_code,
      expire_date: date.setFullYear(date.getFullYear() + 1)
    };
    try {
      const response = await apiService.apiCall(body, "/createCoupon");
      if (response.status) {
        addPaymentLogs(response.data._id, detail);
        navigate("/congratulation", {
          state: {
            coupon: coupon_code,
            page: "coupon",
            amount: detail.total_amount,
            top_up: isTopUp
          },
        });
      }
    } catch (error) {
      toast.error("Something Went Wrong!", {
        position: "top-right",
        autoClose: 1000,
      });
    } finally {
      setLoader(false);
    }
  };

  const addPaymentLogs = async (prepaid_id, detail) => {
    const body = {
      ...detail,
      prepaid_card_id: prepaid_id,
    };
    await apiService.apiCall(body, "/addPaymentLog");
  };

  function handleSelect(data) {
    setCountryCode(data.value);
    setError({ ...error, countryCode: null });
  }

  const handleCheckout = (detail, type) => {
    addCoupon(detail);
  };

  const handleValidate = async () => {
    console.log(page);
    setLoader(true);
    try {
      const response = await apiService.apiCall({ code }, "/validateCode");
      if (response.status) {
        toast.success("Prepaid Code Found Successfully!", {
          position: "top-right",
          autoClose: 1000,
        });
        const prepaidCard = response.data.prepaidCard;
        setMobile(prepaidCard.phone_no);
        setCouponCode(prepaidCard.coupon_code);
        setTopUpDetail(prepaidCard);
        setIsTopUp(true);
//        setPage('amount');
      } else {
        toast.error("Invalid Prepaid Code!", {
          position: "top-right",
          autoClose: 1000,
        });
      }
    } catch (error) {
      toast.error("Something Went Wrong!", {
        position: "top-right",
        autoClose: 1000,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleTopUp = (page) => {
    if (page === "amount") {
      setPage("amount");
      setMobile("");
      setCouponCode(generateCouponCode(4));
      checkCode();
    } else if (page === 'topUp') {
      setPage("topUp");
      setMobile(topUpDetail?.phone_no || '')
//      setTopUpDetail(null);
//      setIsTopUp(false);
    } else {
      setPage("history");
    }
  };

  const handleClick = async (e, search) => {
    if (((e && e.key === 'Enter') || search) && historyCode) {
      setLoader(true);
      try {
        const response = await apiService.apiCall({ code: historyCode }, "/validateCode");
        if (response.status) {
          toast.success("Prepaid Code Found Successfully!", {
            position: "top-right",
            autoClose: 1000,
          });
          setPaymentDetail(response.data.payment_details);
        } else {
          toast.error("Invalid Prepaid Code!", {
            position: "top-right",
            autoClose: 1000,
          });
        }
      } catch (error) {
        toast.error("Something Went Wrong!", {
          position: "top-right",
          autoClose: 1000,
        });
      } finally {
        setLoader(false);
      }
    }
  }

  return (
    <>
      <div style={{ padding: "0 15px 15px 15px" }}>
        <Header />
        {(page === "amount" || page === "topUp" || page === "history") && (
          <div className="mt-5 prepaid">
            <ul className="nav nav-tabs">
              <li
                className="nav-item tab-item"
                onClick={() => handleTopUp("amount")}
              >
                <span
                  className={page === "amount" ? "nav-link active" : "nav-link"}
                  aria-current="page"
                >
                  Buy New Prepaid Coupon
                </span>
              </li>
              <li
                className="nav-item tab-item"
                onClick={() => handleTopUp("topUp")}
              >
                <span
                  className={page === "topUp" ? "nav-link active" : "nav-link"}
                >
                  Top Up
                </span>
              </li>
              <li
                  className="nav-item tab-item"
                  onClick={() => handleTopUp("history")}
              >
                <span
                  className={page === "history" ? "nav-link active" : "nav-link"}
                >
                  Transaction
                </span>
              </li>
            </ul>
          </div>
        )}
        <div className="coupon-sec">
          <div className="wrapper">
            {(page === "amount" || (page === "topUp" && isTopUp)) && (
              <div className="top_up_section">
                <div>
                  <h3>Prepare Credit Coupon Code</h3>
                  <span>
                    Get Printing Power: Secure Your Credit Coupon Now!
                  </span>
                  <div className="form-sec">
                    <form>
                      <div className="block-element">
                        <p>Amount</p>
                        <div className="amout-block">
                          {[5, 10, 15, 50, 100].map((i, index) => (
                            <div
                              key={index}
                              onClick={() => setAmount(i)}
                              className={`amount-text ${
                                amount === i ? "active" : ""
                              }`}
                            >
                              {i} KD
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="grop">
                        <label>Enter Your Phone Number</label>
                        <div className="d-flex">
                          <Select
                            className="select-option"
                            value={{
                              label: `(${countriesData[defaultOptionIndex].dialCode}) ${countriesData[defaultOptionIndex].name} - ${countriesData[defaultOptionIndex].code}`,
                              value: countriesData[defaultOptionIndex].dialCode,
                            }}
                            isDisabled={true}
                            options={countriesData.map((country) => ({
                              label: `(${country.dialCode}) ${country.name} - ${country.code}`,
                              value: country.dialCode,
                            }))}
                            placeholder="Select Country Code"
                            onChange={handleSelect}
                            isSearchable={true}
                          />
                          <input
                            type="number"
                            placeholder="Mobile Number"
                            value={mobile}
                            className="number-input"
                            onChange={(e) => {
                              setMobile(e.target.value);
                              setError({ ...error, mobile: null });
                            }}
                          />
                        </div>
                        {error.countryCode && (
                          <div style={{ color: "red" }}>
                            {error.countryCode}
                          </div>
                        )}
                        {error.mobile && (
                          <div style={{ color: "red" }}>{error.mobile}</div>
                        )}
                        <span>
                          Please ensure accurate input of your phone number as
                          the prepaid code will be sent to this number.
                        </span>
                      </div>
                      <div className="form-btn-block">
                        {page === "payment" && (
                          <button
                            className="form-btn unfill"
                            type="button"
                            onClick={() => setPage("amount")}
                          >
                            <img src={blackArrow} alt="arrow" />
                            Back
                          </button>
                        )}
                        <button
                          className="form-btn"
                          onClick={handlePage}
                          type="button"
                        >
                          Next
                          <img src={btnArrow} alt="arrow" />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                {(isTopUp && page === 'topUp') && (
                  <div className="top-up-details">
                    <h4 className="mb-4">Prepaid Card Detail</h4>
                    <div className="detail">
                      <label>Coupon Code:</label>{" "}
                      <span>{topUpDetail.coupon_code}</span>
                    </div>
                    <div className="detail">
                      <label>Amount:</label> <span>{topUpDetail.amount}</span>
                    </div>
                    <div className="detail">
                      <label>Remaining Amount:</label>{" "}
                      <span>{topUpDetail.remaining_amount}</span>
                    </div>
                  </div>
                )}
              </div>
            )}
            {page === "history" && (
                <>
                    <div className="search-group">
                        <button className="btn upload-btn"
                            onClick={() => {setPaymentDetail(null); setHistoryCode('')}}>Clear</button>
                        <div className="search-box search-code-box">
                          <img src={search} alt="search" className="img-code-search"/>
                          <input type="text" className="form-control search-input" placeholder="Search Code"
                          value={historyCode} onChange={(e) => setHistoryCode(e.target.value)}
                          onKeyPress={handleClick}/>
                          <button className="btn upload-btn ms-1"
                          onClick={() => handleClick(null, true)}>Search</button>
                        </div>
                    </div>
                    <div className="table-container">
                      <table className="custom-table">
                        <thead>
                          <tr>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                            {(paymentDetail && paymentDetail.length) ? paymentDetail.map((i, index) => (
                                <tr key={index}>
                                    <td>{i.final_amount}</td>
                                    <td>{moment(i.created_at).format('YYYY-MM-DD')}</td>
                                    <td>{i.payment_for === 'prepare_card_top_up' ? 'Credited' : 'Debited'}</td>
                                </tr>
                            ))
                            :
                              <tr>
                                <td colSpan="6" align="center">No Data Found</td>
                              </tr>
                            }
                        </tbody>
                      </table>
                    </div>
                </>
            )}
            {page === "success" && (
              <Checkout
                selectedAmt={amount}
                handlePage={handlePage}
                handleCheckout={handleCheckout}
                coupon_code={coupon_code}
                country_code={countryCode}
                phone_no={mobile}
                isTopUp={isTopUp}
              />
            )}
            {page === "payment" && <Success handlePage={handlePage} />}
            {page === "topUp" && !isTopUp && (
              <>
                <h3>Prepaid Code</h3>
                <div className="input-text mt-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Code"
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
                <div className="mt-3">
                  <button
                    className="form-btn"
                    onClick={handleValidate}
                    disabled={!code}
                  >
                    Validate
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
      {loader && (
        <div className="loadWrapper">
          <div className="loader"></div>
        </div>
      )}
    </>
  );
}

function generateCouponCode(length) {
  const characters = "0123456789";
  let couponCode = "";
  for (let i = 0; i < length; i++) {
    couponCode += characters.charAt(
      Math.floor(Math.random() * characters.length)
    );
  }
  return couponCode;
}

export default Coupon;
