import React, { useState } from 'react'
import './checkout.css';
import masterCard from '../../../Assets/images/mastercard.png'
import visa from '../../../Assets/images/visa.png'
import knet from '../../../Assets/images/knet.png'
import couponCodeImg from '../../../Assets/images/coupon.png'
import { ToastContainer, toast } from 'react-toastify';
import apiService from '../../services/api-service.js';

const Checkout = ({selectedAmt, handlePage, handleCheckout, coupon_code, country_code, phone_no, isTopUp}) => {

    const [amount, setAmount] = useState(selectedAmt);
    const [discountAmt, setDiscountAmt] = useState(0);
    const [couponCode, setCouponCode] = useState('');
    const [couponDetail, setCouponDetail] = useState('');
    const [loader, setLoader] = useState(false);
    const [isApply, setIsApply] = useState(false);
    const date = new Date();

    const handleApply = async () => {
        setLoader(true);
        try {
          const response = await apiService.apiCall({coupon_code: couponCode}, '/validateCoupon');
          if (response.status) {
              toast.success('Coupon Apply Successfully!', { position: 'top-right', autoClose: 1000 });
              setIsApply(true);
              setCouponDetail(response.data.disocuntcoupon[0]);
              const discountPercentage = response.data.disocuntcoupon[0].discount_percentage;
              const discount = (Number(amount) * Number(discountPercentage)) / 100;
              const discountedAmount = Number(amount) - Number(discount);
              setAmount(discountedAmount);
              setDiscountAmt(Number(amount) - Number(discountedAmount));
          } else {
              setIsApply(false);
              toast.error('Invalid Coupon Code!', { position: 'top-right', autoClose: 1000 });
          }
        } catch (error) {
          toast.error('Something Went Wrong!', { position: 'top-right', autoClose: 1000 });
        } finally {
          setLoader(false);
        }
    }

    const handleClick = async () => {
        setLoader(true);
        if (amount === 0) {
            const body = {
                discount_card_id: couponDetail._id,
                total_amount: selectedAmt,
                coupon_amount: discountAmt,
                final_amount: amount,
                type: 'discount',
                system: "website",
                payment_for: isTopUp ? "prepare_card_top_up" : "prepare_card",
                status: "success",
                payment_details: {},
                coupon_code
            }
            handleCheckout(body);
        } else {
            const body = {
                src: "src_kw.knet",
                type: "prepaid",
                SUCCESS_URL: "https://print-and-go-website.tech-and-beyond.com/congratulation",
                ERROR_URL: "https://print-and-go-website.tech-and-beyond.com/fail-payment",
                print_id: couponDetail ? couponDetail._id : "",
                transactionNumber: Date.now(),
                id: "123",
                total_amount: selectedAmt,
                coupon_amount: discountAmt,
                final_amount: amount,
                coupon_code: couponCode,
                discount_card_id: couponDetail._id,
                prepare_card_type: "user",
                prepare_card_description: "",
                prepare_card_amount: selectedAmt,
                prepare_card_expire_date: date.setFullYear(date.getFullYear() + 1),
                prepare_card_coupon_code: coupon_code,
                prepare_card_country_code: country_code,
                prepare_card_phone_no: phone_no,
                lang: "en",
            }
            try {
              const response = await apiService.apiCall(body, '/payCheckout');
              if (response.status) {
                  window.location.href = response.data.transaction.url;
              }
            } catch (error) {
              toast.error('Something Went Wrong!', { position: 'top-right', autoClose: 1000 });
            } finally {
              setLoader(false);
            }
        }
    }

    return (
        <>
            <fieldset className="option_block_custom-summary">
                <legend className="legend">Payment</legend>
                <div className="column">
                    <span>Price</span>
                    <span className="left-text">{selectedAmt} KWD</span>
                </div>
            </fieldset>
            <div className="payment-type">
                <div className="flex-box">
                    <h2 className="payment-title">Pay Using Prepaid Code</h2>
                    <h5 className="payment-sub-title">Use Coupon Code At Payment</h5>
                    <div className="image-container">
                        <img className="payment-image" src={couponCodeImg} alt="" srcSet="" />
                    </div>
                    <div className="text-container">
                        {isApply ?
                            <div className="coupon-applied">
                                <h4 className="coupon-title">
                                    <svg height="30px"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512">
                                        <path fill="#02743e"
                                            d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zM227.3 387.3l184-184c6.2-6.2 6.2-16.4 0-22.6l-22.6-22.6c-6.2-6.2-16.4-6.2-22.6 0L216 308.1l-70.1-70.1c-6.2-6.2-16.4-6.2-22.6 0l-22.6 22.6c-6.2 6.2-6.2 16.4 0 22.6l104 104c6.2 6.2 16.4 6.2 22.6 0z" />
                                    </svg>
                                    {discountAmt} KD deducted
                                </h4>
                                <h5 className="coupon-sub-title">1 coupon applied on this print</h5>
                            </div>
                            :
                            <div className="input-container">
                                <label htmlFor="coupon-input">Coupon Code: </label>
                                <input className="input-text" id="coupon-input" type="text"
                                    onChange={(e) => setCouponCode(e.target.value)}
                                    name="coupon_code" />
                            </div>
                        }

                    </div>
                    <button className="upload-btn" onClick={handleApply} disabled={isApply}> Apply</button>
                </div >
                <span className="vertical">Or</span>
                <div className="flex-box">
                    <h2 className="payment-title">Select Payment Option</h2>
                    <h5 className="payment-sub-title">Online Payment (K Net, Mastercard, Visa)</h5>
                    <div className="image-container">
                        <img className="payment-image" src={masterCard} alt="" srcSet="" />
                        <img className="payment-image" src={visa} alt="" srcSet="" />
                        <img className="payment-image" src={knet} alt="" srcSet="" />
                    </div>
                    <div className="text-container" style={{marginTop: 25}}>
                        <h4>Total Payment Amount</h4>
                        <h2>{amount} KD</h2>
                    </div>
                        <button className="upload-btn" onClick={handleClick}>{isTopUp ? 'Checkout' : 'Generate Code'}</button>
                </div>
            </div>
            {loader && (
                <div className="loadWrapper">
                  <div className="loader"></div>
                </div>
            )}
            <ToastContainer />
        </>
    )
}

export default Checkout
