import React, { useEffect } from "react";
import { useNavigate  } from 'react-router-dom';
import banner_image from "../Assets/images/banner_image.png";
import upload from "../Assets/images/upload.svg";
import upload_document from "../Assets/images/upload_mobile.png";
import upload_bg from "../Assets/images/print-1.png";
import upload_bg_two from "../Assets/images/print-2.png";
import arrow from "../Assets/images/arrow.svg";
import tiangle_one from "../Assets/images/banner-triangle-1.png";
import tiangle_two from "../Assets/images/banner-triangle-2.png";
import help_one from "../Assets/images/help-one.png";
import help_one_bg from "../Assets/images/help-one-bg.png";
import help_two from "../Assets/images/help-two.png";
import help_two_bg from "../Assets/images/help-two-bg.png";
import Aos from "aos";
import "aos/dist/aos.css";

function Banner() {

    const navigate = useNavigate();
    useEffect(() => {
        Aos.init({ duration: 1200 });
    }, []);

    const handleDocument = () => {
        navigate('/add-document')
    }

  return (
    <>
      <div className="main">
        <div className="banner-section">
          <div className="container">
            <div className="banner-left" data-aos="fade-down">
              <h1 className="h1">Ministry of Interior</h1>
              <span className="span">
                Print Anything, Anytime, 
                <br />
                Anywhere.
              </span>
              <p className="p" data-aos="fade-down">
                Need a document printed on the go? Don't sweat it. With M.O.I,
                printing is as easy as 1, 2, 3. Find a kiosk near you,
                upload your files, and print instantly. Enjoy the freedom to
                print anything, anytime, anywhere.
              </p>
              <button className="upload-btn" onClick={handleDocument}>
                <img src={upload} alt="upload_icon" />
                <span className="upload-text">Upload & Print in Seconds!</span>
              </button>
            </div>
            <div className="banner-image" data-aos="fade-left">
              <img src={tiangle_one} alt="triale" className="triangle_one" />
              <img src={tiangle_two} alt="triale" className="triangle_two" />
              <img src={banner_image} alt="Banner" />
            </div>
          </div>
        </div>
        <div className="upload_document">
          <div className="container">
            <div className="custom_row">
              <div className="image-right">
                <img
                  className="mobile_img"
                  src={upload_document}
                  alt="upload_document"
                  data-aos="zoom-in"
                />
              </div>
              <div className="upload_coument_right" data-aos="fade-down">
                <span className="blue_button">Convenience on the Go</span>
                <h3 className="h3">Print Anytime, Anywhere</h3>
                <p className="p">
                  Forget waiting in line at print shops or scrambling for a
                  printer. With M.O.I, you can print documents, photos, and
                  more right from your phone, at any of our convenient kiosk
                  locations. No matter where you are or what time it is, your
                  printing needs are covered.
                </p>
              </div>
            </div>
            <div className="bottom-row">
              <div className="printing">
                <div
                  className="upload_coument_right"
                  data-aos="fade-down"
                  data-aos-duration="1500"
                >
                  <span className="blue_button">
                    Mobile Printing Made Easy
                  </span>
                  <h3 className="h3">
                    Upload, Click, Print - It's That Simple!
                  </h3>
                  <p className="p">
                    Our intuitive mobile app makes printing effortless. Simply
                    upload your files, select your preferred kiosk, and hit
                    print. Your documents will be ready in seconds, no need for
                    complicated settings or software.
                  </p>
                </div>
                <div className="print-img" data-aos="fade-down">
                  <img src={upload_bg} alt="Banner" style={{ top: "20px" }}/>
                </div>
              </div>
              <div className="printing" data-aos-duration="1500">
                <div className="upload_coument_right" data-aos="fade-down">
                  <span className="blue_button">Quality You Can Trust</span>
                  <h3 className="h3">Sharp Prints, Every Time</h3>
                  <p className="p">
                    We use high-quality printers and ink to ensure your
                    documents look professional and crisp. Whether you're
                    printing a presentation for work or a cherished photo for
                    memory, you can be confident in the results.
                  </p>
                </div>
                <div className="print-img" data-aos="fade-down">
                  <img src={upload_bg_two} alt="Banner" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="more">
          <div className="container">
            <div
              className="upload_coument_right"
              data-aos="fade-down"
              data-aos-duration="1500"
            >
              <span className="blue_button">Convenience on the Go</span>
              <h3 className="h3" data-aos="fade-left">
                Print More, Save More with Credit Coupons!
              </h3>
              <p className="p" data-aos="fade-right">
                Forget waiting in line at print shops or scrambling for a
                printer. With M.O.I, you can print documents, photos, and
                more right from your phone, at any of our convenient kiosk
                locations. No matter where you are or what time it is, your
                printing needs are covered.
              </p>
              <div className="btn-block" data-aos="zoom-in">
                <button className="upload-btn fill" onClick={() => navigate('/prepaid-coupon')}>
                  <span className="upload-text">Generate Coupon</span>
                  <img src={arrow} alt="upload_icon" />
                </button>
                <button className="upload-btn unfill" data-aos="zoom-in">
                  <span className="upload-text">Help</span>
                </button>
              </div>
            </div>
            <div className="more-img" data-aos="fade-left">
{/*               <img */}
{/*                 src={help_one_bg} */}
{/*                 alt="upload_document" */}
{/*                 className="more_mobaile_bg" */}
{/*               /> */}
              <img
                src={help_one}
                alt="upload_document"
                className="more_mobaile"
              />
            </div>
          </div>
        </div>
        <div className="more grey-bg">
          <div className="container">
            <div className="more-img" data-aos="fade-right">
              <img
                src={help_two_bg}
                alt="upload_document"
                className="more_mobaile_bg"
              />
              <img
                src={help_two}
                alt="upload_document"
                className="more_mobaile"
              />
            </div>
            <div className="upload_coument_right" data-aos="fade-down">
              <span className="blue_button">Convenience on the Go</span>
              <h3 className="h3">Print More, Save More with Credit Coupons!</h3>
              <p className="p">
                Forget waiting in line at print shops or scrambling for a
                printer. With M.O.I, you can print documents, photos, and
                more right from your phone, at any of our convenient kiosk
                locations. No matter where you are or what time it is, your
                printing needs are covered.
              </p>
              <div className="btn-block" data-aos="zoom-in">
                <button className="upload-btn fill" onClick={handleDocument}>
                  <span className="upload-text">Upload Document</span>
                  <img src={arrow} alt="upload_icon" />
                </button>
                <button className="upload-btn unfill">
                  <span className="upload-text">Help</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
