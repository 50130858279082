import React, { useState, useEffect } from "react";
import blackArrow from "../../Assets/images/black-arrow.png";
import btnArrow from "../../Assets/images/btn-arrow.png";
import logo from "../../Assets/images/logo.png";
import { useNavigate  } from 'react-router-dom';
import { Document, Page, pdfjs  } from 'react-pdf';
import countriesData from '../../Assets/country_code.json';
import Select from "react-select";
import Header from '../header.js';
import apiService from '../services/api-service.js';
import { ToastContainer, toast } from 'react-toastify';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


function AddDocument() {

    const navigate = useNavigate();
    const [page, setPage] = useState('add');
    const [mobile, setMobile] = useState('');
    const [countryCode, setCountryCode] = useState('+965');
    const [loader, setLoader] = useState(false);
    const [isGenerate, setIsGenerate] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [imageFile, setImageFile] = useState([]);
    const [pdfFile, setPdfFile] = useState([]);
    const [error, setError] = useState({documents: null, mobile: null, countryCode: null});
    let code = generateCouponCode(4);
    const url = process.env.REACT_APP_API_URL;
    const defaultOptionIndex = countriesData.findIndex(country => country.dialCode === countryCode);

    useEffect(() => {
        if (!isGenerate) {
            checkCode();
        }
    }, []);

    const checkCode = async () => {
        try {
            const response = await apiService.apiCall({code, type: 'coupon'}, '/checkCode');
            if (response.status) {
                code = generateCouponCode(4);
                checkCode();
            } else {
                setIsGenerate(true);
            }
        } catch (error) {
//            toast.error('Something Went Wrong!', { position: 'top-right' });
        }
    }

    const handlePage = async () => {
        if (page === 'add') {
            let errorShow = {};
            if (!(documents && documents.length)) {
                errorShow.documents = '*Please Upload Document';
            }
            if (!countryCode) {
                errorShow.countryCode = '*Please Select Country Code';
            }
            if (!mobile) {
                errorShow.mobile = '*Please Enter Phone Number';
            }
            setError({...error, ...errorShow});
            if (Object.values(errorShow).length) {
                return;
            }
            setLoader(true);
            const formData = new FormData();
            documents.forEach((file) => {
              formData.append('files', file);
            });
            formData.append('country_code', countryCode);
            formData.append('phone_no', mobile);
            formData.append('unique_code', code);
            try {
              const response = await apiService.addDoc(formData, '/createDocument');
              if (response.status) {
                  toast.success('Documents Added Successfully!', { position: 'top-right' });
                  navigate('/congratulation', { state: { code: code, page: 'doc' } });
              } else {
                  toast.error('Not Found Prepaid Code!', { position: 'top-right' });
              }
            } catch (error) {
              toast.error('Something Went Wrong!', { position: 'top-right' });
            } finally {
              setLoader(false);
            }
        }
    }

    const docChange = (event) => {
        if (event.target.files.length > 0) {
          setError({...error, documents: null})
          const file: any = event.target.files?.[0];
          setDocuments([...documents, file]);
          const reader = new FileReader();
          reader.onload = () => {
            if (file.type === 'application/pdf') {
                setPdfFile([...pdfFile, {url: reader.result, time: file.lastModified, name: file.name}]);
            } else if (file.type === 'image/png' || file.type === 'image/jpeg') {
                setImageFile([...imageFile, {url: reader.result, time: file.lastModified, name: file.name}]);
            }
          };
          reader.readAsDataURL(file);
        }
    }

    const removeDoc = (value, index, time) => {
        if (value === 'img') {
            const updatedImageFile = imageFile.filter((_, i) => i !== index);
            setImageFile(updatedImageFile);
        } else {
            const updatedPdfFile = pdfFile.filter((_, i) => i !== index);
            setPdfFile(updatedPdfFile);
        }
        const updatedFile = documents.filter((i) => i.lastModified !== time);
        setDocuments(updatedFile);
    }

    function handleSelect(data) {
        setCountryCode(data.value);
        setError({...error, countryCode: null});
    }

    return(
        <>
        <div style={{ padding: "0 15px 15px 15px" }}>
            <Header />
            <div className="coupon-sec">
                <div className="wrapper">
                    <h3>Upload Document</h3>
                    <span>Get Printing Power: Secure Your Credit Coupon Now!</span>
                    <div className="form-sec">
                        <form>
                            <div className="document">
                                <p style={{ fontWeight: "bold"}}>Add Document</p>
                                {error.documents && <div style={{ color: 'red' }}>{error.documents}</div>}
                                <input type='file' id="edit-file"
                                className="form-file" onChange={docChange} />
                                <label htmlFor="edit-file">
                                <span className="fa fa-upload">+ Add Document</span>
                                </label>
                            </div>
                            <div className="show-pdf-action">
                             {imageFile && imageFile.length > 0 &&
                                imageFile.map((i, index) => (
                                    <div className="show-pdf" key={index}>
                                        <div className="img-wrapper">
                                            <img src={i.url} alt="Preview" />
                                        </div>
                                        <span className="file-name">{i.name}</span>
                                        <button type="button"
                                        className="btn btn-danger button-pdf-cancel"
                                        onClick={() => removeDoc('img', index, i.time)}>Cancel</button>
                                    </div>
                                ))
                                }
                                {pdfFile && pdfFile.length > 0 &&
                                    pdfFile.map((i, index) => (
                                        <div className="show-pdf" key={index}>
                                          <Document file={i.url}>
                                            <Page pageNumber={1} />
                                          </Document>
                                          <span className="file-name">{i.name}</span>
                                          <button type="button"
                                          className="btn btn-danger button-pdf-cancel"
                                          onClick={() => removeDoc('pdf', index, i.time)}>Cancel</button>
                                        </div>
                                    ))
                                }
                            </div>

                            <div className="grop">
                                <label>Enter Your Phone Number</label>
                                <div className="d-flex">
                                    <Select className="select-option"
                                      value={{ label: `(${countriesData[defaultOptionIndex].dialCode}) ${countriesData[defaultOptionIndex].name} - ${countriesData[defaultOptionIndex].code}`,
                                                value: countriesData[defaultOptionIndex].dialCode }}
                                      isDisabled={true}
                                      options={countriesData.map(country => ({
                                        label: `(${country.dialCode}) ${country.name} - ${country.code}`,
                                        value: country.dialCode
                                      }))}
                                      placeholder="Select Country Code"
                                      onChange={handleSelect}
                                      isSearchable={true}
                                    />
                                    <input type="number" placeholder="Mobile Number"
                                    className="number-input"
                                    onChange={(e) => {
                                    setMobile(e.target.value);
                                    setError({...error, mobile: null})}} />
                                </div>
                                {error.countryCode && <div style={{ color: 'red' }}>{error.countryCode}</div>}
                                {error.mobile && <div style={{ color: 'red' }}>{error.mobile}</div>}
                                <span>Please ensure accurate input of your phone number as the your documents print Code will be sent to this number.</span>
                            </div>
                        <div className="form-btn-block">
                            {page === 'add' &&
                                <button className="form-btn unfill" type="button" onClick={() => setPage('upload')}>
                                <img src={blackArrow} alt="arrow" />Back</button>
                            }
                            <button className="form-btn" onClick={handlePage} type="button">
                                Next<img src={btnArrow} alt="arrow" /></button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <ToastContainer />
        {loader && <div className="loadWrapper">
          <div className="loader"></div>
        </div>}
        </>
    );
}

function generateCouponCode(length) {
  const characters = '0123456789';
  let couponCode = '';
  for (let i = 0; i < length; i++) {
    couponCode += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return couponCode;
}

export default AddDocument;