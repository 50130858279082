import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Banner from "./banner";
import IndividualIntervalsExample from "./slider";
import Slider2 from "./Slider2";
import Footer from "./Footer";

function Home() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <div>
          <Banner />
        <IndividualIntervalsExample />
        <Slider2 />
        <Footer />

        </div>
      </div>
  );
}

export default Home;
